.dialog-container {
  position: relative;
  width: 100%;
  background-color: #ffffff;
}

.dialog-container > button{
  width: 100%;
  padding: 7px;
  transition: all 0.2s; 
  border: none;
  outline: none;
  background-color: #484848;
  
}
.dialog-container > button > span{
  width: 100%;
  margin-left: 10px;
  color: #ffffff;
  font-size: 14px;
}

.dialog-container > button:hover{
  transition: all 0.2s;
  background-color: #1f1e1e;
}

.dialog-input-search{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 10px; */
  padding: 5px;
  background-color:#32465a;
}

.dialog-input-search-icon {
  margin: 0 15px;
}

.dialog-input-search > input{
  width: 60%;
  padding: 3px;
  padding-bottom: 9px;
    padding-top: 9px;
  background-color:#32465a;
  font-size: 16px;
  outline: none;
  border: none;
  color: #d2d2d2;
}

.dialog-input-search > input::placeholder{
  color: #d2d2d2;
  font-size: 16px;
}

.dialog-info-container{
  position: relative;
  height: calc(100vh - 204px);
}

.dialog-container ::-webkit-scrollbar{
  width: 10px;
}

.dialog-container ::-webkit-scrollbar-thumb {
  background: #243140; 
}
.dialog-info-container ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.dialog-info-container ul li{
  height: 75px;
  padding-left:15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 5px solid transparent;
  cursor: pointer;
}

.dialog-info-container ul li:hover{
  cursor: pointer;
  background: #32465a;
  /* border-right: 5px solid #435f7a; */
}

.dialog-wrap-block {
  display: flex;
  flex-direction: row;
  width: 85%;
  margin: 10px;
  padding-right: 15px;
  min-width: 85%;
  justify-content: space-between;
  align-items: center;
  -webkit-font-smoothing: antialiased;
}

.dialog-wrap-block-left {
  display: flex;
  flex-direction: column;
}


.dialog-wrap-block-left span{
  display: block;
  max-width: 100%;
  color: #484848;
  font-size: 0.9em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dialog-wrap-block-left h5{
  color: #484848;
  font-weight: 600;
  font-size: 1em;
  max-width: 100%;
  text-overflow: ellipsis; 
  white-space: nowrap;
  overflow: hidden;
  font-family: Arial, sans-serif !important;

}

.dialog-wrap-block-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 30%;
}

.dialog-wrap-block-right p {
  margin: 0;
  padding: 0;
  color: #484848;
  font-size: 0.9em;
  margin: 0 0 5px 0;
}

.chat_datetime p{
  font-size: 11px !important;
}
.dialog-wrap-block-right span {
  display: flex;
  border-radius: 50%;
  font-size: 0.9em;
  color: #ffffff;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background-color: #228d1c;
}


.dialog-no-chats {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #484848;
  font-family: Arial, sans-serif !important;
}
ul.dialog-ul > div > div > div > div:nth-of-type(even) li {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ccc ;
}

ul.dialog-ul > div > div > div > div:nth-of-type(odd) li {
  background-color: #ffffff;
  border-bottom: 1px solid #ccc ;

}
ul.dialog-ul > div > div > div > div li.dialog-li:hover {
  background-color: #f0f9ff;
}
.dialog-container button {
  border: none;
  background-color: #4f4f4f;
  color: #ffffff;
  padding: 11px;
}
button.contactsshown {
  width: 80%;
}
button.dialog-oldmessage {
  width: 20%;
}
button.dialog-newmessage {
  width: 100%;
}
button:focus{
  outline: unset !important;
}
.dialog-wrap-avatar
{
  width: 15%;
}