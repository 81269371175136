.chat-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: #e6eaea;
}

.chat-container > header {
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  width: 100%;
  height: 60px;
  background-color: #f5f5f5;
  align-items: center;
}

.chat-container > header > h3 {
  margin: 0 0 0 10px;
  padding: 0;
  display: block;
  font-size: 1.1em;
  font-weight: 400;
  padding-right: 10px;
}

.chat-container > header > button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px;
  width: 65px;
  margin-right: 15px;
  background-color: transparent;
  padding-left: 15px;
}

.chat-container > .chat-body{
  width: 100%;
  height: Calc(100vh - 156px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-container > .chat-no-selected-dialog{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
}

.chat-container > .chat-no-selected-dialog > p{
  font-size: 1.3em;
}

.chat-container > .chat-body ::-webkit-scrollbar{
  width: 10px;
}

.chat-container > .chat-body ::-webkit-scrollbar-thumb {
  background: #243140; 
}
@media screen and (max-width:1023px){
  .default-grid.col-xl-9.col-lg-7.col-md-7.col-sm-12 {
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
      background: #fff;
}
}
@media screen and (max-width:767px){
  .home-active-container {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
    background: #fff;
}
.chat-container header {
  /* background: #fff; */
}
.home-frame{
  height:auto !important;
}
}
@media screen and (max-width:1024px){
  .home-frame{
    height:auto !important;

  }
}
.chat-message-container-position-left { position: relative;}
.chat-message-left-footer { position: absolute; right: 0; bottom: 0;}









