.user-rofile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user-profile-modal{
  position: absolute;
  z-index: 5;
  background-color: white;
  width: 180px;
  right: 50px;
  top: 70px;
  box-shadow: 0 2px 5px 0 black;
}

.user-profile-modal > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.user-profile-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 25px;
}

.user-profile-user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
}

.user-profile-user-info > span{
  margin-left: 15px;
  font-size: 17px;
  color: #484848;
}

.online{
  padding: 3px;
  border: 2px solid #2ecc71;
  border-radius: 50%;
}

.user-profile-icon{
  padding: 2px 6px;
  cursor: pointer;
}

.user-profile-user-more-info{
  width: 100%;
}

.user-profile-user-more-info > a{
  transition: all 0.3s;
  display: block;
  color: black;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  background-color: white;
  padding: 10px;
  width: 100%;
}

.user-profile-user-more-info > a:hover{
  transition: all 0.3s;
  background-color: #e0e0e0;
}

.Overlay-user-profile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(5, 5, 5, 0.1);
}