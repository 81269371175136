@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
body{
  margin: 0;
  padding: 0;
}

.auth-main-сontainer {
  display: flex;
  align-items: center; 
  justify-content: center;
  height: 100vh;
}

.auth-modal-container {
  width: 320px;
  position: absolute;
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: #c4c7ca;
  background-color: white;
}

.auth-wrapp-loader{
  position: absolute;
  width: 100%;
  height: 100% ;
}

.auth-wrapper{
  margin: 20px;
}
.auth-logo{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.auth-logo img{
  width: 200px;
  height: 125px;
}

.auth-form{
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.auth-form > input{
  padding: 12px 20px;
  margin: 10px 0;
  border: 1px solid #eee;
  border-radius: 25px;
  background-color:#eee;
  color:#837e82;
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  font-weight: 200;
  outline:none;
}

.auth-form > button{
  padding: 12px;
  margin: 15px 0;
  border: 1px solid #00e3cf;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 25px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  background-color:#00e3cf;
  color: white;
  outline:none;
}

.auth-form > button:hover{
  border: 1px solid #00e3cf;
  background-color:white;
  color: black;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.auth-footer{
  width: 100%;
  margin-top: 20px;
  text-align: center;
  font-size: 17px;
  font-family: 'Open Sans', sans-serif;
}
.auth-footer > span{
  display: block;
  color: black;
  font-weight: 300;
  margin: 10px;
}

.auth-footer > a{
  color: black;
  border-bottom: 1px solid black;
  font-weight: 600;
  cursor: pointer;
}

.auth-footer > a:hover{
  border-bottom: none;
}
.container-loader{
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-bottom: 6px solid #3498db;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.user-rofile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user-profile-modal{
  position: absolute;
  z-index: 5;
  background-color: white;
  width: 180px;
  right: 50px;
  top: 70px;
  box-shadow: 0 2px 5px 0 black;
}

.user-profile-modal > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.user-profile-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 25px;
}

.user-profile-user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
}

.user-profile-user-info > span{
  margin-left: 15px;
  font-size: 17px;
  color: #484848;
}

.online{
  padding: 3px;
  border: 2px solid #2ecc71;
  border-radius: 50%;
}

.user-profile-icon{
  padding: 2px 6px;
  cursor: pointer;
}

.user-profile-user-more-info{
  width: 100%;
}

.user-profile-user-more-info > a{
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: block;
  color: black;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  background-color: white;
  padding: 10px;
  width: 100%;
}

.user-profile-user-more-info > a:hover{
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: #e0e0e0;
}

.Overlay-user-profile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(5, 5, 5, 0.1);
}
.avatar-as-icon{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.avatar-as-icon > p{
  display: flex;
  width: 100%;
  height: 100%;
  color: white;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.dialog-container {
  position: relative;
  width: 100%;
  background-color: #ffffff;
}

.dialog-container > button{
  width: 100%;
  padding: 7px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; 
  border: none;
  outline: none;
  background-color: #484848;
  
}
.dialog-container > button > span{
  width: 100%;
  margin-left: 10px;
  color: #ffffff;
  font-size: 14px;
}

.dialog-container > button:hover{
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background-color: #1f1e1e;
}

.dialog-input-search{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 10px; */
  padding: 5px;
  background-color:#32465a;
}

.dialog-input-search-icon {
  margin: 0 15px;
}

.dialog-input-search > input{
  width: 60%;
  padding: 3px;
  padding-bottom: 9px;
    padding-top: 9px;
  background-color:#32465a;
  font-size: 16px;
  outline: none;
  border: none;
  color: #d2d2d2;
}

.dialog-input-search > input::-webkit-input-placeholder{
  color: #d2d2d2;
  font-size: 16px;
}

.dialog-input-search > input:-ms-input-placeholder{
  color: #d2d2d2;
  font-size: 16px;
}

.dialog-input-search > input::-ms-input-placeholder{
  color: #d2d2d2;
  font-size: 16px;
}

.dialog-input-search > input::placeholder{
  color: #d2d2d2;
  font-size: 16px;
}

.dialog-info-container{
  position: relative;
  height: calc(100vh - 204px);
}

.dialog-container ::-webkit-scrollbar{
  width: 10px;
}

.dialog-container ::-webkit-scrollbar-thumb {
  background: #243140; 
}
.dialog-info-container ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.dialog-info-container ul li{
  height: 75px;
  padding-left:15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 5px solid transparent;
  cursor: pointer;
}

.dialog-info-container ul li:hover{
  cursor: pointer;
  background: #32465a;
  /* border-right: 5px solid #435f7a; */
}

.dialog-wrap-block {
  display: flex;
  flex-direction: row;
  width: 85%;
  margin: 10px;
  padding-right: 15px;
  min-width: 85%;
  justify-content: space-between;
  align-items: center;
  -webkit-font-smoothing: antialiased;
}

.dialog-wrap-block-left {
  display: flex;
  flex-direction: column;
}


.dialog-wrap-block-left span{
  display: block;
  max-width: 100%;
  color: #484848;
  font-size: 0.9em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dialog-wrap-block-left h5{
  color: #484848;
  font-weight: 600;
  font-size: 1em;
  max-width: 100%;
  text-overflow: ellipsis; 
  white-space: nowrap;
  overflow: hidden;
  font-family: Arial, sans-serif !important;

}

.dialog-wrap-block-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 30%;
}

.dialog-wrap-block-right p {
  margin: 0;
  padding: 0;
  color: #484848;
  font-size: 0.9em;
  margin: 0 0 5px 0;
}

.chat_datetime p{
  font-size: 11px !important;
}
.dialog-wrap-block-right span {
  display: flex;
  border-radius: 50%;
  font-size: 0.9em;
  color: #ffffff;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background-color: #228d1c;
}


.dialog-no-chats {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #484848;
  font-family: Arial, sans-serif !important;
}
ul.dialog-ul > div > div > div > div:nth-of-type(even) li {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ccc ;
}

ul.dialog-ul > div > div > div > div:nth-of-type(odd) li {
  background-color: #ffffff;
  border-bottom: 1px solid #ccc ;

}
ul.dialog-ul > div > div > div > div li.dialog-li:hover {
  background-color: #f0f9ff;
}
.dialog-container button {
  border: none;
  background-color: #4f4f4f;
  color: #ffffff;
  padding: 11px;
}
button.contactsshown {
  width: 80%;
}
button.dialog-oldmessage {
  width: 20%;
}
button.dialog-newmessage {
  width: 100%;
}
button:focus{
  outline: unset !important;
}
.dialog-wrap-avatar
{
  width: 15%;
}
.sidebar-container{
  background-color: #ffffff;
  height: 100%;
  border-right: 1px solid #ccc;
}
.image-picker{
  display: flex;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  border: 1px solid #27ae60;
  border-radius: 50%;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.image-crop-picker-header {
  height: 40px;
  width: 300px;
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.image-crop-picker-header > button{
  border: none;
  padding: 0 15px;
  outline: none;
  background-color: white;
}

.image-crop-picker-container{
  background-color: #777777;
  position: relative;
  width: 300px;
  height: 300px;
}

.image-picker .custom-file-upload {
  display: flex;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.image-picker > label > svg {
  font-size: 30px;
  color: #27ae60;
}

.custom-file-upload-attachment {
  width: 60px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-file-upload-attachment > svg {
  font-size: 20px;
}

.image-picker input[type="file"], .image-picker-container input[type="file"]{
  display: none;
}




footer {
  /* display: flex; */
  justify-content: center;
  width: 100%;
}

footer > form{
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 850px;
  height: 60px;
  background-color: #e6eaea;
}

 footer > form > input{
  width: 100%;
  color: #32465a;
  font-size: 1em;
  font-weight: 300;
  padding-left: 15px;
  border: none;
  outline: none;
}

 footer > form > .chat-attachment{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 70px;
  height: 60px;
}

 footer > form > .chat-attachment > svg{
  font-size: 18px;
}
 footer > form >  .chat-attachment > svg:hover{
  color: #435f7a;
}

 footer > form > button {
  width: 70px;
  height: 60px;
  border: none;
  outline: none;
  background-color: #32465a;
}

 footer > form > button:hover{
  background-color: #435f7a;
}
.chat-message-layout {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  display: flex;
  justify-content: center; 
}

.chat-message-wrap {
  width: 850px;
  display: flex;
}

.chat-message-wrap-right{
  justify-content: flex-end;
  text-align: end;
}

.chat-message-wrap-left{
  justify-content: flex-start;
  text-align: start;
}

.chat-message-avatar {
  width: 30px;
  margin: 5px 5px 0 0;
}

.chat-message-container-position-right {
  min-width: 120px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: white;
  text-align: left;
  align-items: center;
  margin: 10px 0 10px 0;
  padding: 5px;
}

.chat-message-container-position-left {
  min-width: 120px;
  align-items: flex-start;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #435f7a;
  align-items: center;
  margin: 10px 0 10px 0;
  padding: 5px;
}

.chat-message-container-position-right > span {
  color: #32465a;
  font-weight: 100;
  padding: 0 5px;
  font-size: 16px;
  line-height: 1.5;
}

.chat-message-container-position-left > span {
  color: #f5f5f5;
  font-weight: 100;
  padding: 0 5px;
  font-size: 16px;
  line-height: 1.5;
  padding-bottom: 50px;
}
.chat-message-container-position-left > span, p, div
{
  font-size: 16px !important;
  line-height: 1.5 !important;
}
.chat-message-container-position-right > span, p, div
{
  font-size: 16px !important;
  line-height: 1.5 !important;
}
.chat-message-right-footer > span {
  display: block;
  text-align: right;
  padding-right: 1px;
  width: 100%;
  height: 20px;
  font-size: 13px;
  color: #a0a0a0;
}

.chat-message-left-footer > span {
  display: block;
  text-align: right;
  padding-right: 5px;
  width: 100%;
  height: 20px;
  font-size: 13px;
  color: #cbcbcb;
}

.chat-message-right-footer > span > svg{
  font-size: 12px;
  margin-left: 7px;
}

.chat-message-container-attachment { 
  width: 250px;
  height: 250px;
  padding: 3px
}

.overlay-chat-attachment{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(247, 246, 246, 0.8);
}

.active-window-modal-attachment{
  display: flex;
  flex-direction: column;
}

.active-window-modal-attachment > svg {
  font-size: 30px;
  position: absolute;
  background-color: black;
  border-color: white;
  border-radius: 50%;
  right: 0;
  outline: none;
  cursor: pointer;
}

.chat-message-container-position-left span a {
  word-break: break-word;
}
.chat-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: #e6eaea;
}

.chat-container > header {
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  width: 100%;
  height: 60px;
  background-color: #f5f5f5;
  align-items: center;
}

.chat-container > header > h3 {
  margin: 0 0 0 10px;
  padding: 0;
  display: block;
  font-size: 1.1em;
  font-weight: 400;
  padding-right: 10px;
}

.chat-container > header > button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px;
  width: 65px;
  margin-right: 15px;
  background-color: transparent;
  padding-left: 15px;
}

.chat-container > .chat-body{
  width: 100%;
  height: Calc(100vh - 156px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-container > .chat-no-selected-dialog{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
}

.chat-container > .chat-no-selected-dialog > p{
  font-size: 1.3em;
}

.chat-container > .chat-body ::-webkit-scrollbar{
  width: 10px;
}

.chat-container > .chat-body ::-webkit-scrollbar-thumb {
  background: #243140; 
}
@media screen and (max-width:1023px){
  .default-grid.col-xl-9.col-lg-7.col-md-7.col-sm-12 {
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
      background: #fff;
}
}
@media screen and (max-width:767px){
  .home-active-container {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
    background: #fff;
}
.chat-container header {
  /* background: #fff; */
}
.home-frame{
  height:auto !important;
}
}
@media screen and (max-width:1024px){
  .home-frame{
    height:auto !important;

  }
}
.chat-message-container-position-left { position: relative;}
.chat-message-left-footer { position: absolute; right: 0; bottom: 0;}










.splash-page-container{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center; 
  justify-content: center;
  background-color: #ffffff;
}

.splash-page-container > p {
  font-size: 1.5em;
}
.create-dialog-header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: #f5f5f5;
}

.create-dialog-header > button {
  background-color: transparent;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  outline: none;
}

.create-dialog-header >  button > span {
  padding: 0 5px;
}
.render-search-user {
  display: flex;
  width: 100%;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: 1px solid #27ae60;
}

.render-search-user-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.render-search-user:hover{
  border-right: 5px solid #27ae60;
  border-left: 5px solid #27ae60;
  -webkit-transition:all 0.3s;
  transition:all 0.3s;
}

.render-search-user-info > span{
  font-size: 17px;
  margin: 0;
  padding: 0 0 0 10px;
}

.render-search-user svg{
  margin-right: 10px;
  font-size: 20px;
}
.create-dialog-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e6eaea;
}

.create-dialog-body {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 90%;
  max-width: 550px;
  padding: 10px;
  border: 1px solid #27ae60;
  border-radius: 10px;
}

.create-dialog-body-groupinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.create-dialog-body-groupinfo > input{
  border: none;
  border-bottom: 2px solid #27ae60;
  background-color: transparent;
  margin-left: 5px;
  width: 75%;
  color: black;
  padding: 2px 5px;
  outline: none;
}

.create-dialog-body-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;
}

.create-dialog-body-search > input {
  border: none;
  width: 85%;
  padding: 7px;
  border-radius: 10px;
  outline: none;
}

.create-dialog-body-search > button{
  background-color: #27ae60;
  border: none;
  padding:5px 15px;
  color: white;
  border-radius: 10px;
  outline: none;
}

.create-dialog-body-type-dialog {
  cursor: pointer;
  margin: 20px 0;
  width: 100%;
}

.create-dialog-body-selected-users{
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 550px;
  margin-bottom: 5px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  height: 70px;
}

.create-dialog-body-selected-users > button{
  position: relative;
  background-color: transparent;
  border: none;
  outline: none;
  margin: 5px 5px;
}

.create-dialog-body-selected-users> button > svg {
  display: block;
  position: absolute;
  background-color: white;
  border-radius: 50%;
  bottom: 0px;
  right: 12px;
}

.create-dialog-body-type-dialog button{
  padding: 5px;
  border: none;
  background-color: transparent;
  outline: none;
}

.create-dialog-body-type-dialog > button > svg{
  font-size: 20px;
  margin-right: 10px; 
}

.create-dialog-body > .create-dialog-body-users {
  border-radius: 2px;
  overflow-y: scroll;
}

.create-dialog-body ::-webkit-scrollbar{
  width: 7px;
  height: 7px;
}

.create-dialog-body ::-webkit-scrollbar-thumb {
  background: #27ae60; 
}

.create-dialog-body-btn{
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.create-dialog-body-btn > button {
  width: 60%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: #27ae60;
  color: white;
  outline: none;
}

.create-dialog-body-btn > button:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 60%;
  padding: 5px;
  border: none;
  border-radius: 10px;
  border: 1px solid #27ae60;
  background-color: transparent;
  color: black;
}
body{
  /* font-family: 'Open Sans', sans-serif; */
  font-family: Arial, sans-serif !important;
  background: #f5f7fb;
}
.home-frame{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fa;
  padding-top: 2%;
  margin-bottom: 2%;
}
.home-active-container .default-row.row {
  margin-bottom: 20px;
  float: left;
  background-color: #fff;
  border: 1px solid rgb(203, 210, 214);
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
}
.home-active-container{
  width: 90%;
  margin: 0 auto;
  min-width: 360;
  min-height: 640;
}

@media screen and (min-device-width: 640px){
  .default-grid{
    margin: 0;
    padding: 0;
  }
  .default-row{
    margin: 0;
    padding: 0;
  }
 
}
@media screen and (max-width:767px){
  /* .default-grid.col-xl-3.col-lg-5.col-md-5.col-sm-12{
    padding-top:80px !important;
  } */
  .sidebar-container{
    border-right: 1px solid #ccc;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
  }
  .home-frame{
    padding-top: 98px !important;
    margin-bottom: 30px !important;
  }
}
@media screen and (min-width:768px) and (max-width:1023px){
  .home-active-container{padding-top: 90px !important;}

}
@media screen and (min-width:768px) and (max-width:820px){
  .dialog-info-container ul li{
    padding-left: 10px !important;
  }
  .dialog-wrap-block-right{margin-left: -18px;}
}
body{
  font-family: 'Open Sans', sans-serif;
}

.ss-header{
    z-index: 1;
    background: #fff;
    color: #fff;
    padding-bottom: 15px;
    box-shadow: 0 1px 5px 2px rgba(0,0,0,.2);
    position: fixed;
    width: 100%;
}


.cc-rightside-menus {
    margin-top: 0px;
}
.cc-rightside-menus {
    width: 25%;
}
.cc-rightside-menus .cc-hide-mobile a {
    color: #2c2e2f;
}

a:visited {
    color: #007bff;
}
.cc-dashboard-header-section { background: #fff; color: #fff; padding-bottom: 15px; box-shadow: 0 1px 5px 2px rgba(0,0,0,.2); position: relative; width:100%; z-index: 99999; } 
.cc-dashboard-header-section ul { list-style:none; padding:0px; }
.cc-dashboard-header-section .cc-container {width: 70%; padding-left: 15px; margin-right: auto; margin-left: auto; }/* width: 95% */
.cc-logo-wrapper img { max-width: 285px; }
.cc-menu-section { padding: -1px 15px; }/*  padding: 0px 15px; */
.cc-logo-wrapper {
    float: left;
}
.cc-rightside-menus {
    float: right;
}
.cc-menu-section ul { display: flex; margin-bottom:0px; }
.cc-menu-section ul li a {padding:20px 10px;}
.cc-menu-section .cc-menu ul > li > a{ border-right:1px solid transparent; border-left:1px solid transparent; }
.cc-menu-section ul li a{ color: #2c2e2f; float: left; text-decoration: none; background: #fff; font-size: 15px; position: relative;}
.cc-menu-section ul li a i { position: relative; right: 0; bottom: 0; padding-left: 5px; top: 2px; }
.cc-menu-section .cc-submenu { position: absolute; top: 61px; left:0px; width: 195px; background-color: #fff; box-shadow: 0 0 3px 0px #ccc; border-radius: 0 4px 4px 4px; border: 1px solid #ccc; padding: 0 10px 5px !important; z-index: 1; display:none; }
.cc-menu-section ul li:last-child{ border-bottom:0px !important; }
.cc-submenu li a { padding: 10px 0 !important;  display: block !important; width: 100%; }
.cc-menu-section .cc-menu ul > li.cc-submenu-li:hover > a { border-color:  #ccc; }
.cc-submenu-li:hover .cc-submenu { display:block }
.cc-close-gear-popup{
    position: absolute;
    right: 8px;
    top: 3px;
    cursor: pointer;

}

.cc-settings-wrapper.cc-question-wrapper .cc-section-content {
    max-width: 200px;
}


   

.cc-rightside-menus { width:15%; padding:11px 20px; margin-top: 5px;/* 15px */}
.cc-notifications {position:relative;}
.cc-notifications .cc-notifiy-count{ position: absolute; background-color: #fa3e3e; font-size: 10px; right: -2px; border-radius: 2px; color: #fff; padding: 1px 3px; line-height: 1.3; min-height: 13px; top: -8px; left: 8px; }
.cc-rightside-menus ul  { display: flex;  align-items: center; margin-bottom: 0;}
.cc-settings-wrapper { position:relative; }
.cc-settings-wrapper .cc-setting-options { display:none; }
.cc-settings-wrapper .cc-setting-options.show { display:block; }
.cc-arrow-section { position: absolute; z-index: 22222; top: -15px; right: 34px; }
.cc-setting-options .cc-section-content { border: 1px solid rgba(0,0,0,.1); border-radius: 6px; box-shadow: 0 5px 10px rgba(0,0,0,.1); margin-top: 22px; max-width: 300px; width: 300px; outline: 0; overflow-wrap: normal; padding: 1px; position: absolute; right: -30px; top: 26px; z-index: 9999; background-color: #fff; }
.cc-setting-options .cc-section-content .cc-title { border-bottom: 1px dotted #cbd2d6; padding: 12px 18px 12px 12px; line-height: 25px; color: #555555; margin:0px; }
.cc-setting-options .cc-section-content .cc-title p { margin:0px !important; }
.cc-setting-options .cc-section-content .cc-title span { color: #005EA6; font-size: 15px; font-weight: 500; }
.cc-setting-options .cc-account a{ padding: 12px 22px 12px 20px;; border-bottom: 1px dotted #cbd2d6; display:flex; font-size: 15px; font-weight: 500; color: #003087; }
.cc-account-img{ width: 40px; height: 26px; background-image: url(https://www.taylorrobinsonmusic.com/templates/rwd_taylor/images/hawk-sprite2x.png); background-size: auto 144px; background-position: -55px -9px; background-repeat: no-repeat; }
.cc-pro-setting {padding: 12px 22px 12px 20px;; display:flex; font-size: 15px; font-weight: 500; color: #003087; align-items: center; }
.cc-pro-setting img { margin-left: 3px; }
.cc-pro-setting span { margin-left: 8px;color: #005EA6; }


.cc-mobile-menu-section { display:none }
.cc-show-mobile { display:none;  }
.cc-logoutbtn a{ background-color: #fff; border: 1px solid #005EA6; color: #005EA6; font-weight: bold; font-size: 11px; padding: 6px 15px; border-radius: 19px; cursor: pointer; line-height: 20px; display: block; text-decoration: none; }

.cc-menu-section ul {
    display: flex;
    margin-bottom: 0px;
}
.cc-dashboard-header-section ul {
    list-style: none;
    padding: 0px;
}
.cc-menu-section .cc-menu ul > li {
    position: relative;
}
.cc-row {
    display: flex;
    justify-content: center;

}
.cc-menu-section .cc-submenu {
    position: absolute;
    top: 61px;
    left: 0px;
    width: 195px;
    background-color: #fff;
    box-shadow: 0 0 3px 0px #ccc;
    border-radius: 0 4px 4px 4px;
    border: 1px solid #ccc;
    padding: 0 10px 5px !important;
    z-index: 1;
    display: none;
}
.cc-menu-section ul li a:hover {
    color: #005EA6;
}
.cc-menu-section .cc-menu ul > li > a {
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
}
html body .cc-menu-section ul li a {
    font-size: 15px;
}
.cc-menu-section ul li {
    padding: 0 !important;
    border-bottom: 1px solid #ccc;
    height: 42px;
}
.cc-menu-section ul li:last-child {
    border-bottom: 0px !important;
}
ul.cc-submenu li a {
    border-bottom: 1px solid #cccccc;
}
/* ul.cc-submenu li a:last-child {
    border-bottom: 0px !important;
} */
@media only screen and (max-width: 1023px)
{
    .cc-mobile-menu ul > li > a:hover {
        color: #005EA6;
    }
.cc-show-mobile {
    display: block;
}
.cc-show-mobile .cc-menubar span {
    height: 3px;
    width: 28px;
    display: block;
    background: #005EA6;
    margin-bottom: 6px;
}
.cc-show-mobile .cc-menubar {
    margin-top: 8px;
}
}
@media screen and (min-width:768px){
   
}
@media screen and (max-width:1023px){
    .cc-mobile-menu-section {
        background-color: #fff;
        position: relative;
        z-index: 99999;
        box-shadow: 0 0 2px 1px #ccc;
        -webkit-transition: all linear 0.4s;
        transition: all linear 0.4s;
    }
    li.cc-hide-mobile.cc-logoutbtn {
        display: none;
    }
    .cc-menu-section{display:none !important;}
    .cc-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .cc-logo-wrapper img {
        max-width: 188px;
    }
    .cc-mobile-menu-section {
        background-color: #fff;
        position: fixed;
        right: -400px;
        top: 60px;
        z-index: 99999;
        padding: 18px 10px 10px;
        height: auto;
        overflow-y: auto;
        box-shadow: 0 0 2px 1px #ccc;
        /* padding-bottom: 170px; */
        max-width: 300px;
        width: 40%;
        -webkit-transition: all linear 0.4s;
        transition: all linear 0.4s;
        min-width: 300px;
    }
    .cc-mobile-menu-section.inside {
        right: 1px;
    }
    .cc-mobile-menu ul li a {
        padding: 8px 20px 8px 0;
        color: #555;
        display: block;
        font-size:14px;
    }
    .cc-mobile-menu ul > li > a {
        position: relative;
    }
    .cc-dashboard-header-section .cc-container{width:100% !important;padding-left:10px !important;}
    .cc-mobile-menu-section.inside{ /* for chrome and safari*/
        -webkit-animation-duration: 1s;
        -webkit-animation-name: slidein;
    
        /*for firefox*/
        -moz-animation-duration: 1s;
        -moz-animation-name: slidein;
    
          /* for opera*/
        -o-animation-duration: 1s;
        -o-animation-name: slidein;
    
          /* Standard syntax*/
        animation-duration: 1s;
        animation-name: slidein;
    
    }
    .cc-mobile-menu-section{
        -webkit-animation-duration: 1s;
        -webkit-animation-name: slidein;
    
        /*for firefox*/
        -moz-animation-duration: 1s;
        -moz-animation-name: slidein;
    
          /* for opera*/
        -o-animation-duration: 1s;
        -o-animation-name: slidein;
    
          /* Standard syntax*/
        animation-duration: 1s;
        animation-name: slidein;

    }
    .cc-mobile-menu-section{
        display:block !important;
    }
    .cc-dashboard-header-section{
        position: fixed !important;
    }
    .cc-mobile-submenu {
        padding-left: 15px !important;
    }
   
}
@-webkit-keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}
  @keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}
.cc-mobile-menu ul > li > a
{
    text-decoration: none;
}
@media only screen and (max-width: 1023px)
{
svg.svg-inline--fa.fa-angle-down.fa-w-10 {
    float: right;
}

}
li.cc-submenu-li:hover>a {
    z-index: 999;
}
ul.cc-submenu li:last-child a {
    border-bottom: none;
}
.modal-open .modal
{
    top: 20%;
}
#textareaSupportMenu
{
    width: 100%;
    max-width: 100%;
    height: 145px;
}
.popup_header{
    text-align: center;
    font-size: 18px !important
}
.popup_buttons
{
    background-color: #fff;
    border: 1px solid #005EA6;
    color: #005EA6;
    font-weight: bold;
    font-size: 11px;
    padding: 6px 15px;
    border-radius: 19px;
    cursor: pointer;
    line-height: 20px;
}
.popup_buttons:hover
{
    background-color: transparent;
    box-shadow: 0 0 0 1px #003087 inset;
    border-color: #003087;
    color: #333;
}
.popup_buttons:focus
{
    background-color: transparent;
    box-shadow: 0 0 0 1px #003087 inset;
    border-color: #003087;
    color: #333;
}
.modal-footer{
    justify-content: center;
}
#err_notice_support, #success_notice_support{
    font-size: 14px !important;
}
.loadergif, .smallloadergif{
    background-image: url(https://www.taylorrobinsonmusic.com/templates/rwd_taylor/images/loader.gif)!important;
    background-repeat: no-repeat!important;
    background-position: center center!important;
    background-size: 19px!important;
    color: transparent;
}
.smallloadergif:focus
{
  
    color: transparent;
}

.cc-pro-setting a, .cc-account a {
    text-decoration: none;
}

.cc-question-wrapper .cc-pro-setting.cc-support-setting {
    border-bottom: 1px dotted #cbd2d6;
}
.cc-settings-acc, .cc-faquestion
{
    cursor:pointer !important;
}
.cc-user-name
{
    font-size: 14px !important;
}
@media screen and (max-width: 375px) {
    
    .cc-logo-wrapper { flex: 50% 1 }
    
    
}
@media screen and (min-width: 375px) {
    
    
    .cc-logo-wrapper { flex: 50% 1 }
    
    
    
}
@media screen and (min-width: 425px) {
    
    .cc-logo-wrapper { flex: 50% 1 }
    
    
    
}
@media screen and (min-width: 768px) {
    
    .cc-logo-wrapper { flex: 25% 1 }
    
    
}
@media screen and (min-width: 2560px) {
    
    .cc-logo-wrapper {padding-left: 42px; }
    
}

.modal-backdrop.show {
    opacity: .5;
    width: 99%;
    height: 100%;
}
@media (min-width: 768px){
.modal-dialog {
    width: 600px;
    margin: 30px auto;
}
}

@media (min-width: 576px){
.modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
}
}

@media screen and (min-width:1024px) and (max-width:1100px){
.cc-rightside-menus1
{
    margin-left: 13px;
    margin-top: 10px;
}
.cc-dashboard-header-section .cc-container {
    width: 96%;
}
}

.cc-notification
{
    margin-right: 13px;
}

.cc-rightside-menus1
{
    margin-left: 20px;
    margin-top: 10px;
}

@media screen and (min-width:320px) and (max-width:1023px){
    .cc-question-wrapper
{
    margin-right: -24px;
}
.cc-rightside-menus1 {
    margin-top: -3px;
    margin-right: 20px;
}
}

/* designed by hema */
@media screen and (min-width: 1101px) and (max-width: 1398px){
    .cc-dashboard-header-section .cc-container {
        width: 89%;
    }
       
}

 @media screen and (max-width:385px){
 .custom_info {
    width:256px;
    margin: auto;
    word-break: break-all;
}}

@media screen and (min-width:1025px) {
    .cc-logo-wrapper {
        flex: 0 1 !important;
    }
    .cc-row.header-cc-sec {
        justify-content: start;
    }
    .cc-rightside-menus1 {
        position: relative;
        /*left: -26px;*/
        margin-top: 16px;
    }
}
@media screen and (max-width:374px) {
    .cc-logo-wrapper img {
        max-width: 170px;
    }
    .cc-rightside-menus li img, li.cc-settings-wrapper.cc-question-wrapper svg{
        width: 20px;
    }
    .cc-notification{
        margin-right: 7px;
    }
    li.cc-settings-wrapper.cc-question-wrapper{
        padding-left: 9px !important ;
    }
    .cc-rightside-menus{
        padding: 4px 0 0 34px;
        justify-content: space-around;
        display: flex;
    }
}
.cc-notification-acc
{
    cursor: pointer;
}

@media screen and (max-width:375px) {

    .cc-section-content 
    {
        left: -210px;
    }
    .cc-arrow-section
    {
        left: 208px;
    }
    .section-content-profile {
        left: -132px;
    }
    .arrow-section-profile
    {
        left: 140px;
    }

}

.footer_area .footer_top {
    padding-bottom: 90px;
    padding-top: 90px;
    position: relative;
    color: #fff;
}
.footer_top {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}
@media screen and (min-width: 768px)
{
    .footer_top {
        background-image: url(/static/media/footer_banner.89b3b90a.png);
    }
}
@media (min-width: 1200px)
{
    .container, .navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
        width: 1170px;
    }
}
@media (min-width: 1200px)
{
    .container {
        width: 1170px;
    }
}
@media (min-width: 992px)
{
    .container {
        width: 970px;
    }
}
@media (min-width: 768px)
{
    .container {
        width: 750px;
    }
}
.container {
    padding-right: 15px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
}
.footer_top .row {
    padding: 0!important;
}
.row {
    margin: 0px;
}
@media (min-width: 1200px)
{
.row {
    margin-left: -30px;
    *zoom: 1;
}
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}
.custom.plcall_wrapper, .moduletable {
    position: relative;
}
@media (min-width: 768px)
{
    .col-sm-3 {
        width: 25%;
    }
}
@media (min-width: 768px)
{
    .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
        float: left;
    }
}
.footer_widget h4 {
    font-family: 'Montserrat', sans-serif;
}
.footer_widget h4 {
    font-size: 17px;
    font-weight: 800;
    margin-bottom: 35px;
    color: #fff;
    width: 65%;
    border-bottom: 2px solid #00aeef;
    line-height: 30px;
    text-transform: uppercase;
}
.module-title {
    text-align: left;
}
.footer_bottom {
    background: #00aeef;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
}
.footer_bottom_text, .footer_bottom_text a {
    color: #fff!important;
}
footer .footer_top .footer_widget .menu li a {
    color: #fff;
    padding-bottom: 20px;
    position: relative;
    text-transform: uppercase;
    margin-bottom: 0;
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}
@media (max-width: 979px) and (min-width: 768px)
{
.container, .navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
    width: 724px;
}
}
@media (min-width: 768px)
{.container {
    width: 750px;
}}

.footer_top .container {
    width: auto;
}
footer.footer_area ul {
    list-style: none;
    padding: 0;
}
footer .footer_top .footer_widget .menu li a:hover {
    text-decoration: none;
}
footer .footer_top .footer_widget .menu li  {
   padding-bottom: 20px !important;
}
@media screen and (max-width:767px){
    .footer_top {
        background-image: url(/static/media/mobile_footer_banner.dc687379.png);
    }
    article#module-162 {
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
        width: 50%;
    }
    p.text-left.leftContent {
        font-family: "Montserrat" !important;
    }

}
@media screen and (min-width:320px) and (max-width:350px){
    article#module-162{    width: 55% !important;}
}
