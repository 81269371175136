.create-dialog-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e6eaea;
}

.create-dialog-body {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 90%;
  max-width: 550px;
  padding: 10px;
  border: 1px solid #27ae60;
  border-radius: 10px;
}

.create-dialog-body-groupinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.create-dialog-body-groupinfo > input{
  border: none;
  border-bottom: 2px solid #27ae60;
  background-color: transparent;
  margin-left: 5px;
  width: 75%;
  color: black;
  padding: 2px 5px;
  outline: none;
}

.create-dialog-body-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;
}

.create-dialog-body-search > input {
  border: none;
  width: 85%;
  padding: 7px;
  border-radius: 10px;
  outline: none;
}

.create-dialog-body-search > button{
  background-color: #27ae60;
  border: none;
  padding:5px 15px;
  color: white;
  border-radius: 10px;
  outline: none;
}

.create-dialog-body-type-dialog {
  cursor: pointer;
  margin: 20px 0;
  width: 100%;
}

.create-dialog-body-selected-users{
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 550px;
  margin-bottom: 5px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  height: 70px;
}

.create-dialog-body-selected-users > button{
  position: relative;
  background-color: transparent;
  border: none;
  outline: none;
  margin: 5px 5px;
}

.create-dialog-body-selected-users> button > svg {
  display: block;
  position: absolute;
  background-color: white;
  border-radius: 50%;
  bottom: 0px;
  right: 12px;
}

.create-dialog-body-type-dialog button{
  padding: 5px;
  border: none;
  background-color: transparent;
  outline: none;
}

.create-dialog-body-type-dialog > button > svg{
  font-size: 20px;
  margin-right: 10px; 
}

.create-dialog-body > .create-dialog-body-users {
  border-radius: 2px;
  overflow-y: scroll;
}

.create-dialog-body ::-webkit-scrollbar{
  width: 7px;
  height: 7px;
}

.create-dialog-body ::-webkit-scrollbar-thumb {
  background: #27ae60; 
}

.create-dialog-body-btn{
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.create-dialog-body-btn > button {
  width: 60%;
  transition: all 0.3s;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: #27ae60;
  color: white;
  outline: none;
}

.create-dialog-body-btn > button:hover {
  transition: all 0.3s;
  width: 60%;
  padding: 5px;
  border: none;
  border-radius: 10px;
  border: 1px solid #27ae60;
  background-color: transparent;
  color: black;
}