.chat-message-layout {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  display: flex;
  justify-content: center; 
}

.chat-message-wrap {
  width: 850px;
  display: flex;
}

.chat-message-wrap-right{
  justify-content: flex-end;
  text-align: end;
}

.chat-message-wrap-left{
  justify-content: flex-start;
  text-align: start;
}

.chat-message-avatar {
  width: 30px;
  margin: 5px 5px 0 0;
}

.chat-message-container-position-right {
  min-width: 120px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: white;
  text-align: left;
  align-items: center;
  margin: 10px 0 10px 0;
  padding: 5px;
}

.chat-message-container-position-left {
  min-width: 120px;
  align-items: flex-start;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #435f7a;
  align-items: center;
  margin: 10px 0 10px 0;
  padding: 5px;
}

.chat-message-container-position-right > span {
  color: #32465a;
  font-weight: 100;
  padding: 0 5px;
  font-size: 16px;
  line-height: 1.5;
}

.chat-message-container-position-left > span {
  color: #f5f5f5;
  font-weight: 100;
  padding: 0 5px;
  font-size: 16px;
  line-height: 1.5;
  padding-bottom: 50px;
}
.chat-message-container-position-left > span, p, div
{
  font-size: 16px !important;
  line-height: 1.5 !important;
}
.chat-message-container-position-right > span, p, div
{
  font-size: 16px !important;
  line-height: 1.5 !important;
}
.chat-message-right-footer > span {
  display: block;
  text-align: right;
  padding-right: 1px;
  width: 100%;
  height: 20px;
  font-size: 13px;
  color: #a0a0a0;
}

.chat-message-left-footer > span {
  display: block;
  text-align: right;
  padding-right: 5px;
  width: 100%;
  height: 20px;
  font-size: 13px;
  color: #cbcbcb;
}

.chat-message-right-footer > span > svg{
  font-size: 12px;
  margin-left: 7px;
}

.chat-message-container-attachment { 
  width: 250px;
  height: 250px;
  padding: 3px
}

.overlay-chat-attachment{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(247, 246, 246, 0.8);
}

.active-window-modal-attachment{
  display: flex;
  flex-direction: column;
}

.active-window-modal-attachment > svg {
  font-size: 30px;
  position: absolute;
  background-color: black;
  border-color: white;
  border-radius: 50%;
  right: 0;
  outline: none;
  cursor: pointer;
}

.chat-message-container-position-left span a {
  word-break: break-word;
}