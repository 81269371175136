@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet');
body{
  font-family: 'Open Sans', sans-serif;
}

.ss-header{
    z-index: 1;
    background: #fff;
    color: #fff;
    padding-bottom: 15px;
    box-shadow: 0 1px 5px 2px rgba(0,0,0,.2);
    position: fixed;
    width: 100%;
}


.cc-rightside-menus {
    margin-top: 0px;
}
.cc-rightside-menus {
    width: 25%;
}
.cc-rightside-menus .cc-hide-mobile a {
    color: #2c2e2f;
}

a:visited {
    color: #007bff;
}
.cc-dashboard-header-section { background: #fff; color: #fff; padding-bottom: 15px; box-shadow: 0 1px 5px 2px rgba(0,0,0,.2); position: relative; width:100%; z-index: 99999; } 
.cc-dashboard-header-section ul { list-style:none; padding:0px; }
.cc-dashboard-header-section .cc-container {width: 70%; padding-left: 15px; margin-right: auto; margin-left: auto; }/* width: 95% */
.cc-logo-wrapper img { max-width: 285px; }
.cc-menu-section { padding: -1px 15px; }/*  padding: 0px 15px; */
.cc-logo-wrapper {
    float: left;
}
.cc-rightside-menus {
    float: right;
}
.cc-menu-section ul { display: flex; margin-bottom:0px; }
.cc-menu-section ul li a {padding:20px 10px;}
.cc-menu-section .cc-menu ul > li > a{ border-right:1px solid transparent; border-left:1px solid transparent; }
.cc-menu-section ul li a{ color: #2c2e2f; float: left; text-decoration: none; background: #fff; font-size: 15px; position: relative;}
.cc-menu-section ul li a i { position: relative; right: 0; bottom: 0; padding-left: 5px; top: 2px; }
.cc-menu-section .cc-submenu { position: absolute; top: 61px; left:0px; width: 195px; background-color: #fff; box-shadow: 0 0 3px 0px #ccc; border-radius: 0 4px 4px 4px; border: 1px solid #ccc; padding: 0 10px 5px !important; z-index: 1; display:none; }
.cc-menu-section ul li:last-child{ border-bottom:0px !important; }
.cc-submenu li a { padding: 10px 0 !important;  display: block !important; width: 100%; }
.cc-menu-section .cc-menu ul > li.cc-submenu-li:hover > a { border-color:  #ccc; }
.cc-submenu-li:hover .cc-submenu { display:block }
.cc-close-gear-popup{
    position: absolute;
    right: 8px;
    top: 3px;
    cursor: pointer;

}

.cc-settings-wrapper.cc-question-wrapper .cc-section-content {
    max-width: 200px;
}


   

.cc-rightside-menus { width:15%; padding:11px 20px; margin-top: 5px;/* 15px */}
.cc-notifications {position:relative;}
.cc-notifications .cc-notifiy-count{ position: absolute; background-color: #fa3e3e; font-size: 10px; right: -2px; border-radius: 2px; color: #fff; padding: 1px 3px; line-height: 1.3; min-height: 13px; top: -8px; left: 8px; }
.cc-rightside-menus ul  { display: flex;  align-items: center; margin-bottom: 0;}
.cc-settings-wrapper { position:relative; }
.cc-settings-wrapper .cc-setting-options { display:none; }
.cc-settings-wrapper .cc-setting-options.show { display:block; }
.cc-arrow-section { position: absolute; z-index: 22222; top: -15px; right: 34px; }
.cc-setting-options .cc-section-content { border: 1px solid rgba(0,0,0,.1); border-radius: 6px; box-shadow: 0 5px 10px rgba(0,0,0,.1); margin-top: 22px; max-width: 300px; width: 300px; outline: 0; overflow-wrap: normal; padding: 1px; position: absolute; right: -30px; top: 26px; z-index: 9999; background-color: #fff; }
.cc-setting-options .cc-section-content .cc-title { border-bottom: 1px dotted #cbd2d6; padding: 12px 18px 12px 12px; line-height: 25px; color: #555555; margin:0px; }
.cc-setting-options .cc-section-content .cc-title p { margin:0px !important; }
.cc-setting-options .cc-section-content .cc-title span { color: #005EA6; font-size: 15px; font-weight: 500; }
.cc-setting-options .cc-account a{ padding: 12px 22px 12px 20px;; border-bottom: 1px dotted #cbd2d6; display:flex; font-size: 15px; font-weight: 500; color: #003087; }
.cc-account-img{ width: 40px; height: 26px; background-image: url(https://www.taylorrobinsonmusic.com/templates/rwd_taylor/images/hawk-sprite2x.png); background-size: auto 144px; background-position: -55px -9px; background-repeat: no-repeat; }
.cc-pro-setting {padding: 12px 22px 12px 20px;; display:flex; font-size: 15px; font-weight: 500; color: #003087; align-items: center; }
.cc-pro-setting img { margin-left: 3px; }
.cc-pro-setting span { margin-left: 8px;color: #005EA6; }


.cc-mobile-menu-section { display:none }
.cc-show-mobile { display:none;  }
.cc-logoutbtn a{ background-color: #fff; border: 1px solid #005EA6; color: #005EA6; font-weight: bold; font-size: 11px; padding: 6px 15px; border-radius: 19px; cursor: pointer; line-height: 20px; display: block; text-decoration: none; }

.cc-menu-section ul {
    display: flex;
    margin-bottom: 0px;
}
.cc-dashboard-header-section ul {
    list-style: none;
    padding: 0px;
}
.cc-menu-section .cc-menu ul > li {
    position: relative;
}
.cc-row {
    display: flex;
    justify-content: center;

}
.cc-menu-section .cc-submenu {
    position: absolute;
    top: 61px;
    left: 0px;
    width: 195px;
    background-color: #fff;
    box-shadow: 0 0 3px 0px #ccc;
    border-radius: 0 4px 4px 4px;
    border: 1px solid #ccc;
    padding: 0 10px 5px !important;
    z-index: 1;
    display: none;
}
.cc-menu-section ul li a:hover {
    color: #005EA6;
}
.cc-menu-section .cc-menu ul > li > a {
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
}
html body .cc-menu-section ul li a {
    font-size: 15px;
}
.cc-menu-section ul li {
    padding: 0 !important;
    border-bottom: 1px solid #ccc;
    height: 42px;
}
.cc-menu-section ul li:last-child {
    border-bottom: 0px !important;
}
ul.cc-submenu li a {
    border-bottom: 1px solid #cccccc;
}
/* ul.cc-submenu li a:last-child {
    border-bottom: 0px !important;
} */
@media only screen and (max-width: 1023px)
{
    .cc-mobile-menu ul > li > a:hover {
        color: #005EA6;
    }
.cc-show-mobile {
    display: block;
}
.cc-show-mobile .cc-menubar span {
    height: 3px;
    width: 28px;
    display: block;
    background: #005EA6;
    margin-bottom: 6px;
}
.cc-show-mobile .cc-menubar {
    margin-top: 8px;
}
}
@media screen and (min-width:768px){
   
}
@media screen and (max-width:1023px){
    .cc-mobile-menu-section {
        background-color: #fff;
        position: relative;
        z-index: 99999;
        box-shadow: 0 0 2px 1px #ccc;
        transition: all linear 0.4s;
    }
    li.cc-hide-mobile.cc-logoutbtn {
        display: none;
    }
    .cc-menu-section{display:none !important;}
    .cc-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .cc-logo-wrapper img {
        max-width: 188px;
    }
    .cc-mobile-menu-section {
        background-color: #fff;
        position: fixed;
        right: -400px;
        top: 60px;
        z-index: 99999;
        padding: 18px 10px 10px;
        height: auto;
        overflow-y: auto;
        box-shadow: 0 0 2px 1px #ccc;
        /* padding-bottom: 170px; */
        max-width: 300px;
        width: 40%;
        transition: all linear 0.4s;
        min-width: 300px;
    }
    .cc-mobile-menu-section.inside {
        right: 1px;
    }
    .cc-mobile-menu ul li a {
        padding: 8px 20px 8px 0;
        color: #555;
        display: block;
        font-size:14px;
    }
    .cc-mobile-menu ul > li > a {
        position: relative;
    }
    .cc-dashboard-header-section .cc-container{width:100% !important;padding-left:10px !important;}
    .cc-mobile-menu-section.inside{ /* for chrome and safari*/
        -webkit-animation-duration: 1s;
        -webkit-animation-name: slidein;
    
        /*for firefox*/
        -moz-animation-duration: 1s;
        -moz-animation-name: slidein;
    
          /* for opera*/
        -o-animation-duration: 1s;
        -o-animation-name: slidein;
    
          /* Standard syntax*/
        animation-duration: 1s;
        animation-name: slidein;
    
    }
    .cc-mobile-menu-section{
        -webkit-animation-duration: 1s;
        -webkit-animation-name: slidein;
    
        /*for firefox*/
        -moz-animation-duration: 1s;
        -moz-animation-name: slidein;
    
          /* for opera*/
        -o-animation-duration: 1s;
        -o-animation-name: slidein;
    
          /* Standard syntax*/
        animation-duration: 1s;
        animation-name: slidein;

    }
    .cc-mobile-menu-section{
        display:block !important;
    }
    .cc-dashboard-header-section{
        position: fixed !important;
    }
    .cc-mobile-submenu {
        padding-left: 15px !important;
    }
   
}
@-webkit-keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

@-moz-keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}
 @-o-keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}
  @keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}
.cc-mobile-menu ul > li > a
{
    text-decoration: none;
}
@media only screen and (max-width: 1023px)
{
svg.svg-inline--fa.fa-angle-down.fa-w-10 {
    float: right;
}

}
li.cc-submenu-li:hover>a {
    z-index: 999;
}
ul.cc-submenu li:last-child a {
    border-bottom: none;
}
.modal-open .modal
{
    top: 20%;
}
#textareaSupportMenu
{
    width: 100%;
    max-width: 100%;
    height: 145px;
}
.popup_header{
    text-align: center;
    font-size: 18px !important
}
.popup_buttons
{
    background-color: #fff;
    border: 1px solid #005EA6;
    color: #005EA6;
    font-weight: bold;
    font-size: 11px;
    padding: 6px 15px;
    border-radius: 19px;
    cursor: pointer;
    line-height: 20px;
}
.popup_buttons:hover
{
    background-color: transparent;
    box-shadow: 0 0 0 1px #003087 inset;
    border-color: #003087;
    color: #333;
}
.popup_buttons:focus
{
    background-color: transparent;
    box-shadow: 0 0 0 1px #003087 inset;
    border-color: #003087;
    color: #333;
}
.modal-footer{
    justify-content: center;
}
#err_notice_support, #success_notice_support{
    font-size: 14px !important;
}
.loadergif, .smallloadergif{
    background-image: url(https://www.taylorrobinsonmusic.com/templates/rwd_taylor/images/loader.gif)!important;
    background-repeat: no-repeat!important;
    background-position: center center!important;
    background-size: 19px!important;
    color: transparent;
}
.smallloadergif:focus
{
  
    color: transparent;
}

.cc-pro-setting a, .cc-account a {
    text-decoration: none;
}

.cc-question-wrapper .cc-pro-setting.cc-support-setting {
    border-bottom: 1px dotted #cbd2d6;
}
.cc-settings-acc, .cc-faquestion
{
    cursor:pointer !important;
}
.cc-user-name
{
    font-size: 14px !important;
}
@media screen and (max-width: 375px) {
    
    .cc-logo-wrapper { flex: 50% }
    
    
}
@media screen and (min-width: 375px) {
    
    
    .cc-logo-wrapper { flex: 50% }
    
    
    
}
@media screen and (min-width: 425px) {
    
    .cc-logo-wrapper { flex: 50% }
    
    
    
}
@media screen and (min-width: 768px) {
    
    .cc-logo-wrapper { flex: 25% }
    
    
}
@media screen and (min-width: 2560px) {
    
    .cc-logo-wrapper {padding-left: 42px; }
    
}

.modal-backdrop.show {
    opacity: .5;
    width: 99%;
    height: 100%;
}
@media (min-width: 768px){
.modal-dialog {
    width: 600px;
    margin: 30px auto;
}
}

@media (min-width: 576px){
.modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
}
}

@media screen and (min-width:1024px) and (max-width:1100px){
.cc-rightside-menus1
{
    margin-left: 13px;
    margin-top: 10px;
}
.cc-dashboard-header-section .cc-container {
    width: 96%;
}
}

.cc-notification
{
    margin-right: 13px;
}

.cc-rightside-menus1
{
    margin-left: 20px;
    margin-top: 10px;
}

@media screen and (min-width:320px) and (max-width:1023px){
    .cc-question-wrapper
{
    margin-right: -24px;
}
.cc-rightside-menus1 {
    margin-top: -3px;
    margin-right: 20px;
}
}

/* designed by hema */
@media screen and (min-width: 1101px) and (max-width: 1398px){
    .cc-dashboard-header-section .cc-container {
        width: 89%;
    }
       
}

 @media screen and (max-width:385px){
 .custom_info {
    width:256px;
    margin: auto;
    word-break: break-all;
}}

@media screen and (min-width:1025px) {
    .cc-logo-wrapper {
        flex: 0 !important;
    }
    .cc-row.header-cc-sec {
        justify-content: start;
    }
    .cc-rightside-menus1 {
        position: relative;
        /*left: -26px;*/
        margin-top: 16px;
    }
}
@media screen and (max-width:374px) {
    .cc-logo-wrapper img {
        max-width: 170px;
    }
    .cc-rightside-menus li img, li.cc-settings-wrapper.cc-question-wrapper svg{
        width: 20px;
    }
    .cc-notification{
        margin-right: 7px;
    }
    li.cc-settings-wrapper.cc-question-wrapper{
        padding-left: 9px !important ;
    }
    .cc-rightside-menus{
        padding: 4px 0 0 34px;
        justify-content: space-around;
        display: flex;
    }
}
.cc-notification-acc
{
    cursor: pointer;
}

@media screen and (max-width:375px) {

    .cc-section-content 
    {
        left: -210px;
    }
    .cc-arrow-section
    {
        left: 208px;
    }
    .section-content-profile {
        left: -132px;
    }
    .arrow-section-profile
    {
        left: 140px;
    }

}
