@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet');

body{
  /* font-family: 'Open Sans', sans-serif; */
  font-family: Arial, sans-serif !important;
  background: #f5f7fb;
}
.home-frame{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fa;
  padding-top: 2%;
  margin-bottom: 2%;
}
.home-active-container .default-row.row {
  margin-bottom: 20px;
  float: left;
  background-color: #fff;
  border: 1px solid rgb(203, 210, 214);
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
}
.home-active-container{
  width: 90%;
  margin: 0 auto;
  min-width: 360;
  min-height: 640;
}

@media screen and (min-device-width: 640px){
  .default-grid{
    margin: 0;
    padding: 0;
  }
  .default-row{
    margin: 0;
    padding: 0;
  }
 
}
@media screen and (max-width:767px){
  /* .default-grid.col-xl-3.col-lg-5.col-md-5.col-sm-12{
    padding-top:80px !important;
  } */
  .sidebar-container{
    border-right: 1px solid #ccc;
    -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
  }
  .home-frame{
    padding-top: 98px !important;
    margin-bottom: 30px !important;
  }
}
@media screen and (min-width:768px) and (max-width:1023px){
  .home-active-container{padding-top: 90px !important;}

}
@media screen and (min-width:768px) and (max-width:820px){
  .dialog-info-container ul li{
    padding-left: 10px !important;
  }
  .dialog-wrap-block-right{margin-left: -18px;}
}