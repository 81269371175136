.render-search-user {
  display: flex;
  width: 100%;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: 1px solid #27ae60;
}

.render-search-user-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.render-search-user:hover{
  border-right: 5px solid #27ae60;
  border-left: 5px solid #27ae60;
  transition:all 0.3s;
}

.render-search-user-info > span{
  font-size: 17px;
  margin: 0;
  padding: 0 0 0 10px;
}

.render-search-user svg{
  margin-right: 10px;
  font-size: 20px;
}