@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
.footer_area .footer_top {
    padding-bottom: 90px;
    padding-top: 90px;
    position: relative;
    color: #fff;
}
.footer_top {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}
@media screen and (min-width: 768px)
{
    .footer_top {
        background-image: url(../assets/footer_banner.png);
    }
}
@media (min-width: 1200px)
{
    .container, .navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
        width: 1170px;
    }
}
@media (min-width: 1200px)
{
    .container {
        width: 1170px;
    }
}
@media (min-width: 992px)
{
    .container {
        width: 970px;
    }
}
@media (min-width: 768px)
{
    .container {
        width: 750px;
    }
}
.container {
    padding-right: 15px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
}
.footer_top .row {
    padding: 0!important;
}
.row {
    margin: 0px;
}
@media (min-width: 1200px)
{
.row {
    margin-left: -30px;
    *zoom: 1;
}
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}
.custom.plcall_wrapper, .moduletable {
    position: relative;
}
@media (min-width: 768px)
{
    .col-sm-3 {
        width: 25%;
    }
}
@media (min-width: 768px)
{
    .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
        float: left;
    }
}
.footer_widget h4 {
    font-family: 'Montserrat', sans-serif;
}
.footer_widget h4 {
    font-size: 17px;
    font-weight: 800;
    margin-bottom: 35px;
    color: #fff;
    width: 65%;
    border-bottom: 2px solid #00aeef;
    line-height: 30px;
    text-transform: uppercase;
}
.module-title {
    text-align: left;
}
.footer_bottom {
    background: #00aeef;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
}
.footer_bottom_text, .footer_bottom_text a {
    color: #fff!important;
}
footer .footer_top .footer_widget .menu li a {
    color: #fff;
    padding-bottom: 20px;
    position: relative;
    text-transform: uppercase;
    margin-bottom: 0;
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}
@media (max-width: 979px) and (min-width: 768px)
{
.container, .navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
    width: 724px;
}
}
@media (min-width: 768px)
{.container {
    width: 750px;
}}

.footer_top .container {
    width: auto;
}
footer.footer_area ul {
    list-style: none;
    padding: 0;
}
footer .footer_top .footer_widget .menu li a:hover {
    text-decoration: none;
}
footer .footer_top .footer_widget .menu li  {
   padding-bottom: 20px !important;
}
@media screen and (max-width:767px){
    .footer_top {
        background-image: url(../assets/mobile_footer_banner.png);
    }
    article#module-162 {
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
        width: 50%;
    }
    p.text-left.leftContent {
        font-family: "Montserrat" !important;
    }

}
@media screen and (min-width:320px) and (max-width:350px){
    article#module-162{    width: 55% !important;}
}