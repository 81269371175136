.splash-page-container{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center; 
  justify-content: center;
  background-color: #ffffff;
}

.splash-page-container > p {
  font-size: 1.5em;
}