@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet');

body{
  margin: 0;
  padding: 0;
}

.auth-main-сontainer {
  display: flex;
  align-items: center; 
  justify-content: center;
  height: 100vh;
}

.auth-modal-container {
  width: 320px;
  position: absolute;
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: #c4c7ca;
  background-color: white;
}

.auth-wrapp-loader{
  position: absolute;
  width: 100%;
  height: 100% ;
}

.auth-wrapper{
  margin: 20px;
}
.auth-logo{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.auth-logo img{
  width: 200px;
  height: 125px;
}

.auth-form{
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.auth-form > input{
  padding: 12px 20px;
  margin: 10px 0;
  border: 1px solid #eee;
  border-radius: 25px;
  background-color:#eee;
  color:#837e82;
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  font-weight: 200;
  outline:none;
}

.auth-form > button{
  padding: 12px;
  margin: 15px 0;
  border: 1px solid #00e3cf;
  transition: all 0.3s;
  border-radius: 25px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  background-color:#00e3cf;
  color: white;
  outline:none;
}

.auth-form > button:hover{
  border: 1px solid #00e3cf;
  background-color:white;
  color: black;
  cursor: pointer;
  transition: all 0.3s;
}

.auth-footer{
  width: 100%;
  margin-top: 20px;
  text-align: center;
  font-size: 17px;
  font-family: 'Open Sans', sans-serif;
}
.auth-footer > span{
  display: block;
  color: black;
  font-weight: 300;
  margin: 10px;
}

.auth-footer > a{
  color: black;
  border-bottom: 1px solid black;
  font-weight: 600;
  cursor: pointer;
}

.auth-footer > a:hover{
  border-bottom: none;
}