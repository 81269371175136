.avatar-as-icon{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.avatar-as-icon > p{
  display: flex;
  width: 100%;
  height: 100%;
  color: white;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
}